import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStyles } from "./AccordeonWithInput.style";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { NumericInput } from "./NumericInput";
import { useForm } from "@hooks";

type Props = {
  text: string;
  image?: any;
  incomes?: any;
  noDrop?: boolean;
  value?: number;
};
export const AccordeonTransparentWithInput: React.FC<Props> = ({
  text,
  incomes,
  image,
  value,
  noDrop,
}) => {
  const classes = useStyles();

  const [totalAmount, setTotalAmount] = useState(0);
  const [form] = useForm({});

  useEffect(() => {
    let total = 0;
    incomes?.map(
      (income: {
        name:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined;
        amount: any;
        amounts?: any;
      }) =>
        (total = total + income?.amounts?.reduce((a: any, b: any) => a + b, 0))
    );
    setTotalAmount(total);
    if (text === "Ingreso fijo") {
      form.totalFixedIncome = value || totalAmount;
    } else if (text === "Ingreso variable") {
      form.totalVariableIncome = totalAmount;
    }
  }, [totalAmount]);

  return (
    <Accordion
      elevation={0}
      className={`${classes.accordion} ${noDrop && classes.enabled}`}
    >
      <AccordionSummary expandIcon={!noDrop && <ExpandMoreIcon />}>
        {image && <img src={image} className={classes.accordionIcon} />}

        <div className={classes.summaryRight}>
          <span className={classes.details}>{text}</span>
          <div>
            <NumericInput value={value || totalAmount || 0} name={""} />
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <div className={classes.incomesContainer}>
          {incomes?.map(
            (income: {
              name:
                | boolean
                | React.ReactChild
                | React.ReactFragment
                | React.ReactPortal
                | null
                | undefined;
              amount: any;
              amounts?: any;
            }) => {
              return (
                <div key={income.name}>
                  <div className="incomeName">{income.name}</div>
                  <div className="incomeInput">
                    <NumericInput
                      name="incomes"
                      label=""
                      disabled
                      value={
                        income.amount ??
                        income?.amounts?.reduce((a: any, b: any) => a + b, 0) ??
                        0
                      }
                      onChange={() => {}}
                    />
                  </div>
                </div>
              );
            }
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
