import { rightTriangleArrow } from "@images";
import React from "react";
import { useStyles } from "./nextBlock.style";

type Props = {
  onClick: () => void;
};

const NextBlock: React.FC<Props> = ({ onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.arrowContainer} onClick={onClick}>
        <img src={rightTriangleArrow} />
        <span>Calcular Presupuesto</span>
      </div>
    </div>
  );
};

export default NextBlock;
