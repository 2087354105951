import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  children: {},
  all: {
    borderRadius: "0 0 2rem 2rem",
    background: "linear-gradient(92deg, #4619b6 8%, #8352fd 100%)",
    height: 230,
    fontFamily: "Montserrat",
    color: "white",
    [theme.breakpoints.down(1240)]: {
      height: 150,
    },
  },
  container: {
    display: "flex",
    paddingTop: "5rem",
    justifyContent: "space-between",
    [theme.breakpoints.down(1240)]: {
      paddingTop: "2rem",
      alignItems: "center",
    },
    "& >div": {
      display: "flex",
      alignItems: "center",
    },
    "& .button": {
      width: 400,
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down(1240)]: {
        width: "20%",
        padding: 0,
      },
      "& button": {
        padding: "0.5rem",
        borderRadius: 10,
        border: "1px solid white",
        [theme.breakpoints.down(1240)]: {
          padding: "0 0.3rem",
          "& svg": {
            width: "1rem",
            height: "1rem",
          },
        },
      },
    },
    "& .backIcon": {
      width: 200,
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down(1240)]: {
        width: 80,
      },
      "& img": {
        background: theme.palette.text.primary,
        padding: "0.5rem 0",
        borderRadius: 6,
        width: 52,
        height: 52,
        cursor: "pointer",
        [theme.breakpoints.down(1240)]: {
          width: 28,
          height: 28,
        },
      },
    },
    "& .containerText": {
      [theme.breakpoints.down(1240)]: {
        display: "flex",
        alignItems: "center",
        paddingTop: 5,
      },
      "& .title": {
        fontSize: 36,
        fontWeight: "bold",
        width: "auto",
        [theme.breakpoints.down(1240)]: {
          width: "100%",
          fontSize: 16,
          fontWeight: 300,
          textAlign: "center",
        },
      },
      "& .subtitle": {
        paddingTop: "0.5rem",
        fontSize: 24,
        fontWeight: 300,
      },
    },
  },
  subtitleMobile: {
    fontSize: 20,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    paddingTop: "1.25rem",
  },
}));
