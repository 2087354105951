import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
  LayoutMissionMobile,
  MainSectionM2,
  Simulator2_2,
  TopSection2_2,
  LayoutMissionDesktop,
} from '@components';
import { TopSection, Simulator } from '@components';
import { useMediaQuery } from '@material-ui/core';
//Important to update mission
import { completeMissionMutation, getUserMissions } from '@apollo';
import { useApolloClient } from '@apollo/client';
import { MissionsInfo } from '@data';
import { nextMissionModalInfo } from '@utils';
import { AppContext } from '@context';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';
import { useAuth } from '@hooks';

// Vista para mostrar la mision 2.1, Tema: Ingreso Fijo / fixed income
const BudgetMission2 = () => {
  useAuth({ redirectIfNotLogged: true });
  // agregar if para checkear condición de render
  const [page, setPage] = useState(1);
  const isMobile = useMediaQuery('(max-width: 1240px)');
  const { openCongratsModal } = useContext(AppContext);
  const [confettiFire, setConfettiFire] = useState<boolean>(false);

  const { userMissions } = getUserMissions(); //!! to update mission
  const client = useApolloClient(); //!! to update mission

  const categoryMissions = userMissions.filter(
    (obj) => obj.mission.category == MissionsInfo.BudgetCategory.name
  );
  const currentMission = categoryMissions.filter(
    (obj) => obj.mission.id == MissionsInfo.BudgetCategory.m2.id
  )[0];

  const onHouseClick = useCallback(() => {
    navigate(Route.budget);
  }, []);

  const handleClick = () => {
    // if (currentMission?.state == "PENDING") {
    try {
      completeMissionMutation(client, currentMission.mission.id, data());
      setConfettiFire(true);
      openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    } catch (error) {
      console.log(error);
    }
    // } else {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // }
  };

  useEffect(() => {
    // if (currentMission?.state == "COMPLETED") {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // } else {
    //   setConfettiFire(false);
    // }
    setConfettiFire(false);
  }, [currentMission]);

  const [fixedIncomes, setFixedIncomes] = useState([
    { name: 'sueldo', amount: 0 },
  ]);
  const [variableIncomes, setVariableIncomes] = useState([
    { name: 'honorarios', months: 1, amounts: [0], average: 0 },
  ]);

  const data = () => {
    const fixedIncome = () => {
      if (fixedIncomes.length > 1) {
        return fixedIncomes.reduce((a, b) => a.amount + b.amount);
      } else {
        return fixedIncomes[0].amount;
      }
    };
    const variableIncome = () => {
      if (variableIncomes.length > 1) {
        return variableIncomes.reduce((a, b) => a.average + b.average);
      } else {
        return variableIncomes[0].average;
      }
    };
    const total = fixedIncome() + variableIncome();

    return {
      total: total,
      fixedIncome: {
        total: fixedIncome(),
        fixedIncomes,
      },
      variableIncome: {
        total: variableIncome(),
        variableIncomes,
      },
    };
  };

  if (page === 1) {
    return (
      <>
        {isMobile ? (
          <LayoutMissionMobile
            title="Ingresos fijos y variables"
            number={2}
            confettiFire={confettiFire}
            onHouseClick={() => navigate(Route.budget)}
          >
            <TopSection />
            <Simulator
              setPage={setPage}
              fixedIncomes={fixedIncomes}
              setFixedIncomes={setFixedIncomes}
            />
          </LayoutMissionMobile>
        ) : (
          <LayoutMissionDesktop
            title="Ingresos fijos y variables"
            number={2}
            confettiFire={confettiFire}
            onHouseClick={() => navigate(Route.budget)}
          >
            <TopSection />
            <Simulator
              setPage={setPage}
              fixedIncomes={fixedIncomes}
              setFixedIncomes={setFixedIncomes}
            />
          </LayoutMissionDesktop>
        )}
      </>
    );
  } else if (page === 2) {
    return (
      <>
        {isMobile ? (
          <LayoutMissionMobile
            title="Ingresos fijos y variables"
            number={2}
            secondLayoutForShortcuts
            redirectLink="missions/budget/mision2"
            setPage={setPage}
            pageNumber={1}
            confettiFire={confettiFire}
            onHouseClick={() => navigate(Route.budget)}
          >
            <TopSection2_2 />
            <Simulator2_2
              setPage={setPage}
              setVariableIncomes={setVariableIncomes}
              variableIncomes={variableIncomes}
            />
          </LayoutMissionMobile>
        ) : (
          // falta agregar go back button
          <LayoutMissionDesktop
            title=" Ingresos fijos y variables"
            number={2}
            secondLayoutForShortcuts
            redirectLink="missions/budget/mission2"
            setPage={setPage}
            pageNumber={1}
            confettiFire={confettiFire}
            onHouseClick={() => navigate(Route.budget)}
          >
            <TopSection2_2 />
            <Simulator2_2
              setPage={setPage}
              setVariableIncomes={setVariableIncomes}
              variableIncomes={variableIncomes}
            />
          </LayoutMissionDesktop>
        )}
      </>
    );
  } else if (page === 3) {
    return (
      <>
        {isMobile ? (
          <LayoutMissionMobile
            title="Ingresos fijos y variables"
            number={2}
            secondLayoutForShortcuts
            redirectLink="missions/budget/mision2/2.2"
            setPage={setPage}
            pageNumber={2}
            confettiFire={confettiFire}
            onHouseClick={() => navigate(Route.budget)}
          >
            <MainSectionM2
              handleClick={handleClick}
              fixedIncomes={fixedIncomes}
              variableIncomes={variableIncomes}
            />
          </LayoutMissionMobile>
        ) : (
          // falta agregar go back button
          <LayoutMissionDesktop
            title=" Ingresos fijos y variables"
            number={2}
            secondLayoutForShortcuts
            redirectLink="missions/budget/mision2/2.2"
            setPage={setPage}
            pageNumber={2}
            confettiFire={confettiFire}
            onHouseClick={() => navigate(Route.budget)}
          >
            <MainSectionM2
              handleClick={handleClick}
              fixedIncomes={fixedIncomes}
              variableIncomes={variableIncomes}
            />
          </LayoutMissionDesktop>
        )}
      </>
    );
  }
};
export default BudgetMission2;
