import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "./Header.style";
import { useAuth } from "@hooks";
import { backIcon } from "images/Budgets";
import HomeIcon from "@material-ui/icons/Home";
import { Route } from "@interfaces";
import { navigate } from "gatsby";

interface LayoutProps {
  className?: string;
  title?: string;
  subtitle?: string;
  onTurnBackClick?: () => void;
  subtitleMobile?: string;
}
export const Header: React.FC<LayoutProps> = ({
  className,
  title,
  subtitle,
  onTurnBackClick,
  subtitleMobile,
}) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const { isLoggedIn } = useAuth();
  const classes = useStyles({ isLoggedIn });

  return (
    <div className={`${className || ""} ${classes.all}`}>
      <div className={`${classes.container}`}>
        <div>
          <div className="backIcon">
            <img onClick={onTurnBackClick} src={backIcon} />
          </div>
          <div className="containerText">
            <div className="title">{title}</div>
            {!isMobile && <div className="subtitle">{subtitle}</div>}
          </div>
        </div>
        <div className="button">
          <button
            onClick={() => {
              navigate(Route.rokipedia);
            }}
          >
            <HomeIcon />
            {!isMobile && "Volver a Mis Finanzas"}
          </button>
        </div>
      </div>
      {isMobile && (
        <div className={classes.subtitleMobile}>
          {subtitleMobile || subtitle}
        </div>
      )}
    </div>
  );
};
