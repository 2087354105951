import Simulator from "@components/Missions/Budget/M2/Simulator";
import { LayoutBudget } from "@components/Structural/Budgets/Layout/Layout";
import {
  TopSection,
  Simulator2_2,
  TopSection2_2,
  BudgetCalculator,
} from "@components";
import React, { useState, useContext, useEffect } from "react";
import Step1 from "@components/Budget/Mision3/Step1/Step1";
import Step2 from "@components/Budget/Mision3/Step2/Step2";
import IncomesExpensesRecap from "@components/Structural/Budgets/ToolStep5/IncomesExpensesRecap";
import NextBlock from "@components/Structural/Budgets/ToolStep5/nextBlock";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { completeMissionMutation, getUserMissions } from "@apollo";
import { AppContext } from "@context";
import { MissionsInfo } from "@data";
import { useAuth, useForm } from "@hooks";
import { useApolloClient } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import BudgetMission2 from "pages/missions/budget/mission2";
import { cloneDeep } from "lodash";

const MainBudgetDisplay = () => {
  const classes = useStyles();
  const [fixedIncomes, setFixedIncomes] = useState([
    { name: "sueldo", amount: 0 },
  ]);
  const [variableIncomes, setVariableIncomes] = useState([
    { name: "honorarios", months: 1, amounts: [0], average: 0 },
  ]);
  const [page, setPage] = useState(1);
  useAuth({ redirectIfNotLogged: true });

  const [form] = useForm({});
  const { userMissions } = getUserMissions();
  const [confettiFire, setConfettiFire] = useState<boolean>(false);
  const { openCongratsModal } = useContext(AppContext);
  const client = useApolloClient();
  const [step1Data, setStep1Data] = useState(null);
  const [step2Data, setStep2Data] = useState(null);

  const categoryMissions = userMissions.filter(
    (obj) => obj.mission.category == MissionsInfo.BudgetCategory.name
  );
  const budgetMission2 = categoryMissions.filter(
    (obj) => obj.mission.id == MissionsInfo.BudgetCategory.m2.id
  )[0];
  const budgetMission3 = categoryMissions.filter(
    (obj) => obj.mission.id == MissionsInfo.BudgetCategory.m3.id
  )[0];
  const budgetMission4 = categoryMissions.filter(
    (obj) => obj.mission.id == MissionsInfo.BudgetCategory.m4.id
  )[0];

  const info = () => {
    const incomeResult = mission2Data();
    const expensesResult = mission3Data();
    if (incomeResult && expensesResult) {
      const income = incomeResult;
      const expenses = expensesResult;
      return {
        income: income,
        expenses: expenses,
      };
    } else {
      return {
        income: budgetMission2?.data,
        expenses: budgetMission3?.data,
      };
    }
  };

  useEffect(() => {
    if (info().income) {
      if (info().income.fixedIncome) {
        setFixedIncomes(cloneDeep(info().income?.fixedIncome.fixedIncomes));
      }
      if (info().income.variableIncome) {
        setVariableIncomes(
          cloneDeep(info().income.variableIncome.variableIncomes)
        );
      }
    }
  }, [BudgetMission2]);

  const mission2Data = () => {
    const fixedIncome = () => {
      if (fixedIncomes.length > 1) {
        return fixedIncomes.reduce((a, b) => a.amount + b.amount);
      } else {
        return fixedIncomes[0].amount;
      }
    };
    const variableIncome = () => {
      if (variableIncomes.length > 1) {
        return variableIncomes.reduce((a, b) => a.average + b.average);
      } else {
        return variableIncomes[0].average;
      }
    };
    const total = fixedIncome() + variableIncome();

    return {
      total: total,
      fixedIncome: {
        total: fixedIncome(),
        fixedIncomes,
      },
      variableIncome: {
        total: variableIncome(),
        variableIncomes,
      },
    };
  };

  const mission3Data = () => {
    if (!step1Data || !step2Data) return null;

    const average = () => {
      return fixedAvg() + variableAvg();
    };

    const fixedAvg = () => {
      const amounts = [];
      Object.values(step1Data).forEach((element) => {
        element.forEach((ele) => {
          if (ele.total > 0) {
            amounts.push(ele.total);
          }
        });
      });
      if (amounts.length > 0) {
        return amounts.reduce((a, b) => a + b);
      } else {
        return amounts;
      }
    };

    const variableAvg = () => {
      const amounts = [];
      Object.values(step2Data).forEach((element) => {
        element.forEach((ele) => {
          if (ele.total > 0) {
            amounts.push(ele.total);
          }
        });
      });
      if (amounts.length > 0) {
        return amounts.reduce((a, b) => a + b);
      } else {
        return amounts;
      }
    };

    return {
      total: average(),
      fixed: {
        total: fixedAvg(),
        ...Object.values(step1Data),
      },
      variable: {
        total: variableAvg(),
        ...Object.values(step2Data),
      },
    };
  };

  const saveData = (savingCapacity) => {
    try {
      completeMissionMutation(
        client,
        budgetMission2.mission.id,
        mission2Data()
      );
      completeMissionMutation(
        client,
        budgetMission3.mission.id,
        mission3Data()
      );
      completeMissionMutation(client, budgetMission4.mission.id, {
        savingCapacity: savingCapacity,
      });
    } catch (error) {
      console.log(error);
    }

    navigate(Route.finance);
  };
  // const [isPage, setPage2] = useState(0);

  if (page === 1) {
    return (
      <LayoutBudget
        title="Calculadora de presupuesto"
        subtitle="Paso 1: tus ingresos fijos"
        subtitleMobile="Paso 1/6: tus ingresos fijos"
        onTurnBackClick={() => navigate(Route.rokipedia + "/?#tools")}
      >
        <div className={classes.separator}>
          <TopSection />
          <Simulator
            setPage={setPage}
            setFixedIncomes={setFixedIncomes}
            fixedIncomes={fixedIncomes}
          />
        </div>
      </LayoutBudget>
    );
  } else if (page == 2) {
    return (
      <LayoutBudget
        title="Calculadora de presupuesto"
        subtitle="Paso 2: tus ingresos variables"
        onTurnBackClick={() => setPage(1)}
        subtitleMobile="Paso 2/6: tus ingresos variables"
      >
        <div className={classes.separator}>
          <TopSection2_2 />
          <Simulator2_2
            setPage={setPage}
            variableIncomes={variableIncomes}
            setVariableIncomes={setVariableIncomes}
          />
        </div>
      </LayoutBudget>
    );
  } else if (page == 3) {
    return (
      <LayoutBudget
        title="Calculadora de presupuesto"
        onTurnBackClick={() => setPage(2)}
        subtitle="Paso 3: tus gastos fijos"
        subtitleMobile="Paso 3/6: tus gastos fijos"
      >
        <div className={classes.separator}>
          <div className={classes.centrator}>
            Los
            <strong> gastos fijos </strong>
            son gastos básicos que debes pagar todos los meses. Algunos ejemplos
            son la luz, el arriendo y el agua.
          </div>

          <Step1
            data={info().expenses ? info().expenses?.fixed : null}
            onClick={(stp1Data) => {
              setStep1Data(stp1Data);
              setPage(4);
            }}
          />
        </div>
      </LayoutBudget>
    );
  } else if (page == 4) {
    return (
      <LayoutBudget
        title="Calculadora de presupuesto"
        onTurnBackClick={() => setPage(3)}
        subtitle="Paso 4: tus gastos variables"
        subtitleMobile="Paso 4/6: tus gastos variables"
      >
        <div className={classes.separator}>
          <div className={classes.centrator}>
            Los
            <strong> gastos variables </strong> son cosas en las que gastas
            frecuentemente pero no son esenciales. Algunos ejemplos son la ropa,
            la entretención y las vacaciones.
          </div>
          <Step2
            data={info().expenses ? info().expenses?.variable : null}
            onClick={(stp2Data) => {
              setStep2Data(stp2Data);
              setPage(5);
            }}
          />
        </div>
      </LayoutBudget>
    );
  } else if (page == 5) {
    return (
      <LayoutBudget
        onTurnBackClick={() => setPage(4)}
        title="Calculadora de presupuesto"
        subtitle="Paso 5: cálculo automático de tu presupuesto"
        subtitleMobile="Paso 5/6: cálculo automático"
      >
        <div className={classes.separator}>
          <div className={classes.centratorRight}>
            Con la herramienta presupuesto podrás simular cuántos ingresos y
            gastos tienes en un mes, además de descubrir cuánto puedes ahorrar e
            invertir.{" "}
            <strong>
              En base a la información que has entregado anteriormente:
            </strong>
            <IncomesExpensesRecap data={info()} />
            {/* <MainSection toolLayout />
              <Step3
                toolLayout
                onClick={() => {
                  setPage(5);
                }}
              /> */}
            <NextBlock onClick={() => setPage(6)} />
          </div>
        </div>
      </LayoutBudget>
    );
  } else if (page == 6) {
    return (
      <LayoutBudget
        title="Calculadora de presupuesto"
        onTurnBackClick={() => setPage(5)}
        subtitle="Paso 6: tus resultados"
        subtitleMobile="Paso 6/6: tus resultados"
        confettiFire={confettiFire || false}
      >
        <div className={classes.separator}>
          <div className={classes.centratorRight}>
            <BudgetCalculator
              step={1}
              data={info()}
              handleClick={saveData}
              buttonText={"Guardar y volver a Mis Finanzas"}
              isTool={true}
            />
          </div>
        </div>
      </LayoutBudget>
    );
  }
};

export default MainBudgetDisplay;

const useStyles = makeStyles((theme) => ({
  separator: { margin: "4rem 0 5rem" },
  centrator: {
    margin: "0 auto",
    width: 1100,
    [theme.breakpoints.down(1300)]: {
      width: "375px!important",
      margin: "0 auto",
      fontFamily: "Montserrat",
      fontSize: 12,
      lineHeight: 1.67,
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
  },
  centratorRight: {
    margin: "2rem 10rem",
    [theme.breakpoints.down(1300)]: {
      margin: "0rem 2rem 4rem",
    },
  },
  budgetsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
}));
