import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100vw",
    margin: "4.5rem 0 0 -11rem",
    padding: "2.6875rem 0rem",
    borderRadius: "1.25rem",
    boxShadow: "0.25rem 0.25rem 1.25rem 0.125rem rgba(0,0,0,0.2)",
    backgroundColor: "#00a9c2",
    [theme.breakpoints.down(1300)]: {
      margin: "4.5rem 0 0 -2rem",
      backgroundColor: "#8352fd",
    },
  },
  arrowContainer: {
    cursor: "pointer",
    width: "26.25rem",
    height: "6.25rem",
    margin: "0rem auto",
    display: "flex",
    flexDirection: "row",
    padding: "1.375rem 1.6875rem 1.4375rem",
    borderRadius: "3.125rem",
    boxShadow: "0.25rem 0.25rem 1.25rem 0.125rem rgba(0,0,0,0.2)",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down(1300)]: {
      width: "20.9375rem",
      alignItems: "center",
    },
    "& >img": {
      width: "3.4375rem",
      height: "3.4375rem",
      margin: "0 0.5rem 0 0",
      objectFit: "contain",
      [theme.breakpoints.down(1300)]: {
        width: "2.7437rem",
        height: "2.7437rem",
      },
    },
    "& >span": {
      width: "16.9375rem",
      height: "2.1875rem",
      margin: "0.6875rem 0 0.5625rem 0.5rem",
      fontFamily: "Montserrat",
      fontSize: 24,
      fontWeight: "bold",
      textAlign: "left",
      color: theme.palette.text.primary,
      [theme.breakpoints.down(1300)]: {
        width: "13.5rem",
        height: "1.7437rem",
        margin: "0.6875rem 0 0.5625rem 0.5rem",
        fontFamily: "Montserrat",
        fontSize: "1.1938rem",
        fontWeight: "bold",
        textAlign: "left",
        color: theme.palette.text.primary,
      },
    },
  },
}));
