import React from "react";
import { makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { Confetti, Footer, LayoutOnBoarding } from "@components";
import { useStyles } from "./Layout.style";
import { useAuth } from "@hooks";
import { Header } from "../Header/Header";
interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
  subtitle?: string;
  onTurnBackClick?: () => void;
  subtitleMobile?: string;
  confettiFire?: boolean;
}
export const LayoutBudget: React.FC<LayoutProps> = ({
  className,
  children,
  title,
  subtitle,
  subtitleMobile,
  onTurnBackClick,
  confettiFire,
}) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const { isLoggedIn } = useAuth();
  const classes = useStyles({ isLoggedIn });

  return (
    <div className={`${className || ""} ${classes.container}`}>
      <Header
        title={title}
        subtitle={subtitle}
        onTurnBackClick={onTurnBackClick}
        subtitleMobile={subtitleMobile}
      />
      <div className={classes.children}>
        {" "}
        <Confetti confettiFire={confettiFire || false} />
        {children}
      </div>
      {!isMobile && <Footer className={``} />}
    </div>
  );
};
