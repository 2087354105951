import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  accordion: {
    background: "none",
    border: "none",
    width: "13.375rem",
    "& .MuiAccordion-root:before": {
      height: "0px",
      diplay: "none",
    },
  },
  valueContainer: {
    width: "167.5px",
    height: "47px",
    margin: "0.3125rem 0 0",
    padding: "0.375rem 0.9063rem 0.5rem 1.3125rem",
    borderRadius: "0.625rem",
    boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.1)",
    border: "solid 1px #9d9d9d",
    backgroundColor: "#ffffff",
    "& span": {
      fontSize: 16,
      fontWeight: "bold",
      textAlign: "left",
      width: "8.25rem",
      height: "2.0625rem",
      fontFamily: "Montserrat",
      color: theme.palette.text.primary,
    },
  },
  enabled: {
    pointerEvents: "none",
  },
  details: {
    fontSize: 14,
    textTransform: "uppercase",
    width: 147,
    height: 22,
    fontFamily: "Montserrat",
    fontWeight: 400,
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  summaryRight: {
    display: "flex",
    flexDirection: "column",
  },
  accordionIcon: {
    width: 70,
    height: 70,
    margin: "4px 17px 0 0",
    borderRadius: 10,
  },
  incomesContainer: {
    display: "flex",
    flexDirection: "column",
    "& .incomeInput": {
      boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.1)",
      height: 33,
      width: 167.5,
      "& div": {
        height: 33,
      },
    },
    "& .incomeName": {
      textTransform: "uppercase",
      marginTop: 16,
      width: 147,
      height: 22,
      fontFamily: "Montserrat",
      fontSize: 12,
      fontWeight: 400,
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
  },
  mainIncomeValue: {
    background: "#ffffff",
    fontWeight: "bold",
    color: "#393e44",
  },
}));
