import React, { useState } from "react";
import { useStyles } from "./recap.style";
import { useMediaQuery } from "@material-ui/core";
import { ingresoPromedio, fileUpload, averageSpending } from "@images";
import { AccordeonTransparentWithInput } from "@components/Reusables/AccordeonWithInput";
import { useForm } from "@hooks";
interface IncomeExpensesRecap {
  data: JSON;
}

const IncomesExpensesRecap: React.FC<IncomeExpensesRecap> = ({ data }) => {
  const classes = useStyles();
  const [fixedIncomes, setFixedIncomes] = useState(
    data.income?.fixedIncome.fixedIncomes
  );
  // pedir promedio de ingresos al back
  const [fixedExpenses, setFixedExpenses] = useState([
    {
      name: "Familia",
      amount: data.expenses?.fixed[0].reduce((a: any, b: any) => a + b, 0),
    },
    {
      name: "Casa",
      amount: data.expenses?.fixed[1].reduce((a: any, b: any) => a + b, 0),
    },
  ]);
  // pedir promedio de ingresos al back
  const [variableExpenses, setVariableEspenses] = useState([
    {
      name: "Todos",
      amount: data.expenses?.total,
    },
  ]);
  const isMobile = useMediaQuery("(max-width: 1300px)");

  const defaultValue = 0;

  const variableExpensesAccordeon = data?.expenses?.variable?.total;
  const fixedExpensesAccordeon = data?.expenses?.fixed?.total;

  return (
    <div className={classes.container}>
      <div className={classes.recapContainer}>
        <div className={classes.midSection}>
          {!isMobile ? (
            <>
              {" "}
              <span className="boldLabel">Tus ingresos totales</span>
              <div className={classes.totalContainer}>
                <img src={ingresoPromedio} />
                <div className="totalValue">
                  {/* <span>$</span> */}
                  <img src={fileUpload} />
                  <span>{"$" + data.income?.total.toLocaleString("es")}</span>
                </div>
              </div>
            </>
          ) : (
            <div className={classes.totalContainerMobile}>
              <img src={ingresoPromedio} />
              {/* <span>$</span> */}
              <span className="mobileText">
                Tus <strong> ingresos </strong>totales:
              </span>
              <span className="mobileAmount">
                {" "}
                {data.income?.total.toLocaleString("es")}
              </span>
            </div>
          )}
        </div>
        <div className={`${classes.incomesRecap}`}>
          <AccordeonTransparentWithInput
            text="Ingreso fijo"
            // image={ingresoFijo}
            incomes={fixedIncomes}
            value={data.income?.total}
            noDrop
          />
          <AccordeonTransparentWithInput
            text="Ingreso variable"
            // image={ingresoVariable}
            incomes={data.income?.variableIncome.variableIncomes}
            noDrop
          />
        </div>
      </div>
      <div className={classes.recapContainer}>
        <div className={classes.midSection}>
          {!isMobile ? (
            <>
              <span className="boldLabel">Tus gastos totales</span>

              <div className={classes.totalContainer}>
                <img src={averageSpending} />
                <div className="totalValue">
                  {/* <span>$</span> */}
                  <img className="rotate" src={fileUpload} />

                  <span>{"$" + data.expenses?.total.toLocaleString("es")}</span>
                </div>
              </div>
            </>
          ) : (
            <div className={classes.totalContainerMobile}>
              <img src={averageSpending} />
              {/* <span>$</span> */}
              <span className="mobileText">
                Tus <strong> gastos </strong>totales:
              </span>
              <span className="mobileAmount">
                {data.expenses?.total.toLocaleString("es")}
              </span>
            </div>
          )}
        </div>
        <div className={`${classes.incomesRecap}`}>
          <AccordeonTransparentWithInput
            text="Gasto fijo"
            // image={ingresoFijo}
            incomes={fixedExpenses}
            noDrop
            value={
              fixedExpensesAccordeon > 0
                ? fixedExpensesAccordeon
                : fixedExpensesAccordeon.lenght > 0
                ? fixedExpensesAccordeon
                : 0
            }
          />
          <AccordeonTransparentWithInput
            text="Gasto variable"
            // image={ingresoVariable}
            noDrop
            incomes={variableExpenses}
            value={
              variableExpensesAccordeon > 0
                ? variableExpensesAccordeon
                : variableExpensesAccordeon.lenght > 0
                ? variableExpensesAccordeon
                : 0
            }
          />
        </div>
      </div>
    </div>
  );
};

export default IncomesExpensesRecap;
