import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down(1300)]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  midSection: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    [theme.breakpoints.down(1300)]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      textAlign: "center",
    },
    "& .boldLabel": {
      margin: " 2rem 0 -0.5rem 0",
      fontFamily: "Montserrat",
      fontSize: 24,
      fontWeight: "bold",
      textAlign: "left",
      color: theme.palette.text.primary,
    },
  },
  totalContainer: {
    width: "29.875rem",
    height: "73px",
    margin: "1.8125rem 0rem 0 0",
    padding: "1.1875rem 2rem",
    borderRadius: "1.875rem",
    boxShadow: "0.25rem 0.25rem 1.25rem 0.125rem rgba(0, 0, 0, 0.2)",
    backgroundColor: "#8352fd",

    "& .totalValue": {
      display: "flex",
      [theme.breakpoints.down(1300)]: {
        margin: "160px 19px 0 15px",
      },
      "& >img": {
        width: "2rem",
        height: "2rem",
        margin: "0.0625rem 1.5625rem 0.125rem 0",
        objectFit: "contain",
        filter:
          "invert(100%) sepia(22%) saturate(0%) hue-rotate(52deg) brightness(211%) contrast(100%)",
      },
      "& .rotate": {
        transform: "rotate(180deg)",
      },
    },
    "& span": {
      width: 347.7,
      height: 35,
      fontFamily: "Montserrat",
      fontSize: 24,
      fontWeight: "bold",
      textAlign: "left",
      color: "#ffffff",
      [theme.breakpoints.down(1300)]: {
        color: theme.palette.text.primary,
        width: 268,
        height: 30,
      },
    },
    "& >img": {
      width: "8.75rem",
      // height: 131,
      margin: "-4.875rem 0rem 0rem 15rem",
      borderRadius: "10px",
      position: "absolute",
      [theme.breakpoints.down(1300)]: {
        width: 300,
        height: 160,
        margin: "0px 0px 27px 0px",
        borderRadius: 15,
        boxShadow: "none",
      },
    },
    [theme.breakpoints.down(1300)]: {
      backgroundColor: "#ffffff",
      width: 316,
      height: 264,
      padding: "11px 12px 42px",
      display: "flex",
      flexDirection: "column",
      textAlign: "left",
    },
  },
  recapContainer: {
    display: "flex",
    minHeight: "10rem",
    flexDirection: "column",
    [theme.breakpoints.down(1300)]: {
      alignItems: "center",
    },
  },
  incomesRecap: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down(1300)]: {
      flexDirection: "column",
    },
  },
  totalContainerMobile: {
    backgroundColor: "#ffffff",
    width: "20.9375rem",
    height: "5.8125rem",
    padding: "0.6875rem 0.25rem 0.6875rem 0.75rem",
    boxShadow: "0.25rem 0.25rem 1.25rem 0.125rem rgba(0, 0, 0, 0.2) ",
    display: "flex",
    borderRadius: "1.25rem",
    flexDirection: "column",
    flexWrap: "wrap",
    textAlign: "left",
    "& >img": {
      width: "5rem",
      height: "4.4375rem",
      margin: "0 1.375rem 0 0",
      borderRadius: "0.625rem",
    },
    "& .mobileText": {
      width: "12.4375rem",
      height: "1.875rem",
      fontFamily: "Montserrat",
      margin: "0 1.125rem 0.4875rem 0",
      fontSize: 14,
      fontWeight: "normal",
      textAlign: "left",
      color: theme.palette.text.primary,
    },
    "& .mobileAmount": {
      width: "13.5625rem",
      height: "1.875rem",
      fontFamily: "Montserrat",
      margin: "0rem 0 0",
      fontSize: 24,
      fontWeight: "bold",
      textAlign: "left",
      color: theme.palette.text.primary,
    },
  },
}));
